import BrandLogo from "@components/logos/brand-logo";
import LogoContainer from "@components/primary-navigation/components/logo-container";
import { PepTypography } from "@components/pep-typography";
import { AppBar, Button } from "@infinitaslearning/pixel-design-system";
import { signIn } from "next-auth/react";
import { useTranslation } from "next-i18next";
import type { FC } from "react";
import { NavBox, NavigationStyling } from "./primary-navigation.styles";

const PrimaryNavigationBarUnlogged: FC = () => {
  const { t: tSidebar } = useTranslation("common", { keyPrefix: "user-sidebar" });

  const renderRight = () => (
    <NavigationStyling>
      <Button
        onClick={() => signIn("identity-server4")}
        variant="outlined"
        size="small"
        color="inherit"
      >
        <PepTypography variant="body1Light" t={tSidebar} translationKey="login" />
      </Button>
    </NavigationStyling>
  );

  return (
    <NavBox>
      <AppBar
        sx={{ pl: 0 }}
        logo={
          <LogoContainer>
            <BrandLogo />
          </LogoContainer>
        }
        right={renderRight()}
      />
    </NavBox>
  );
};

export default PrimaryNavigationBarUnlogged;
