import type { FC } from "react";
import Link from "@components/styled-link/styled-link";
import { getAnalyticsTracker } from "@lib/analytics-tracker";
import { useRouter } from "next/router";
import { PepTypography } from "@components/pep-typography";
import { kebabCaseItem } from "@utils/common";
import { slugify } from "@utils/slugify";

type BreadcrumbLinkProps = {
  link: { link: string; title: string | undefined };
};

const BreadcrumbLink: FC<BreadcrumbLinkProps> = ({ link }) => {
  const router = useRouter();
  return (
    <Link
      key={link.link}
      href={{
        pathname: link.link,
        query: {
          classroomId: router.query.classroomId,
          methodName: router.query.methodName,
          subjectName: router.query.subjectName,
        },
      }}
      sx={{
        display: "inline-flex",
        textDecoration: "none",
        alignItems: "center",
      }}
      onClick={() => {
        const title = link.title || "";
        const elementId = `breadcrumb-${slugify(title)}`;
        const type = "link";
        const value = link.link;
        getAnalyticsTracker()?.elementClicked({
          elementId,
          type,
          module: "content-overview",
          itemId: kebabCaseItem(elementId),
          value,
        });
      }}
    >
      <PepTypography>{link.title}</PepTypography>
    </Link>
  );
};

export default BreadcrumbLink;
